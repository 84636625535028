@import "../../scss/config";

.text_card {
  background: $primary-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  height: fit-content;
  width: 100%;
  padding: 22px 24px 17px 27px;
  position: relative;
  .text_card_image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 150px;
  }
}
.image_text_card {
  h6,
  label,
  p {
    margin-left: 150px;
  }
  // .ellipses_end {
  //   padding-left: 155px;
  // }
}
